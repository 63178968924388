body {
  margin: 0;
  font-family: 'Segoe UI', sans-serif;
  background: #222;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.App {
  text-align: center;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.links a {
  margin: 0 1rem;
  color: #1da1f2;
  text-decoration: none;
  font-weight: bold;
}

.links a:hover {
  text-decoration: underline;
}